import axios from 'axios';

export async function getTokens(nsUrl, username, password) {
    return axios.post(`${nsUrl}/api/auth/login`, {
        "username": username,
        "password": password
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((response) => {
            if (response.status === 200) {
                const resp = response.data;
                localStorage.setItem("token", resp.token);
                localStorage.setItem("refreshToken", resp.refreshToken);
                return true;
            } else {
                return false;
            }
        })
        .catch((err) => console.warn(err));
}

export async function getCustomerApplications() {
    return axios.get(`/api/customer/applications`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function getSubCustomerApplications(subCustomerId) {
    return axios.get(`/api/subCustomer/${subCustomerId}/applications`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function refreshTokens() {
    const refresh_token = localStorage.getItem("refreshToken");
    return axios.post(`/api/auth/token`, { refreshToken: refresh_token }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((response) => {
            if (response.status === 200) {
                const resp = response.data;
                localStorage.setItem("token", resp.token);
                localStorage.setItem("refreshToken", resp.refreshToken);
            }
        })
        .catch((err) => console.warn(err));
}

export async function getApplicationDevices(applicationId, seacrhParams = {}) {
    let searchParams = new URLSearchParams({
        sortDirection: "ASC",
        sortField: "NAME",
        ...seacrhParams
    })
    return axios.get(`/api/application/${applicationId}/devices?${searchParams}`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function getCustomerDevices(customerId, seacrhParams = {}) {
    let searchParams = new URLSearchParams({
        sortDirection: "ASC",
        sortField: "NAME",
        ...seacrhParams
    })
    return axios.get(`/api/customer/${customerId}/devices?${searchParams}`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function getSubCustomerDevices(subCustomerId, seacrhParams = {}) {
    let searchParams = new URLSearchParams({
        sortDirection: "ASC",
        sortField: "NAME",
        ...seacrhParams
    })
    return axios.get(`/api/subCustomer/${subCustomerId}/devices?${searchParams}`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function getDeviceById(deviceId) {
    return axios.get(`/api/device/${deviceId}`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function getDevicePackets(deviceId, limit, lastMillis, lastIndex) {
    let searchParams = new URLSearchParams({
        lastMillis: lastMillis || Date.now(),
        limit: limit || 1000, 
        lastIndex: lastIndex || Number.MAX_SAFE_INTEGER,
    })
    return axios.get(`/api/device/${deviceId}/log?${searchParams}`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function getApplicationCredentials(applicationId) {
    return axios.get(`/api/credentials/APPLICATION/${applicationId}`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function getDownlinkQueue(deviceId) {
    return axios.get(`/api/device/${deviceId}/downlink`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function deleteDownlinkQueue(deviceId) {
    return axios.delete(`/api/device/${deviceId}/downlink`)
        .then((response) => response.data)
        .catch((err) => console.warn(err));
}

export async function sendDownlink(payload, port, deviceId, confirmed = false) {
    return axios.post(`/api/device/${deviceId}/downlink`, {
        "confirmed": confirmed,
        "data": payload,
        "encryptedPayload": false,
        "port": parseInt(port)
    }, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {return response})
        .catch((err) => console.warn(err));
}

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.baseURL = localStorage.getItem("host") || '';
    config.headers['X-Authorization'] = token ? `Bearer ${token}` : null;
    config.headers['Accept'] = config.headers['Accept'] || "*/*";
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    console.log("Error", error);
    if (error?.response?.status === 401 && error?.response?.data?.errorCode === 11) {
        if (!error.config._retry) {
            error.config._retry = true;
            console.log("Token has expired!");
            return refreshTokens().then(_ => axios.request(error.config))
        }
        else window.location.href = '/sign-in';
    }
    return Promise.reject(error);
});